import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {StoreNomenclatureComponent} from './components/store-nomenclature/store-nomenclature.component';
import {MovementNomenclatureComponent} from './components/movement-nomenclature/movement-nomenclature.component';

const routes: Routes = [
	{
		path: '',
		// component: NomenclatureStoreCatalogComponent,
		data: {title: 'Номенклатура', breadcrumb: 'Номенклатура'},
		children: [
			{
				path: 'catalog',
				component: StoreNomenclatureComponent,
				data: {breadcrumb: 'Каталог'},
			},
			{
				path: 'movement',
				component: MovementNomenclatureComponent,
				data: {breadcrumb: 'Движение материала'},
			},
		]
	}

];

@NgModule({
	imports: [RouterModule.forChild(routes)]
})
export class NomenclatureRouting {
}
