import {ChangeDetectionStrategy, Component} from '@angular/core';

import {StoreNomenclatureService} from '../../services/store-nomenclature.service';
import {StoreNomenclature} from '@store/data/plan/store/store-nomenclature';
import {MODALS} from '@shared/modals/modals';
import {IWarehouseModalData} from '@shared/modals/warehouse-modal/models/warehouse-modal.types';
import {ModalsService} from '@shared/services/modals.service';

@Component({
	selector: 'app-warehouse-modal-nomenclature',
	templateUrl: './store-nomenclature.component.html',
	styleUrls: ['./store-nomenclature.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreNomenclatureComponent {
	constructor(
		private _dialog: ModalsService,
		public service: StoreNomenclatureService
	) {
	}

	public edit(order: StoreNomenclature): void {
		if (!order.receiptDate) {
			order.receiptDate = new Date();
		}

		const data: IWarehouseModalData = {
			title: 'Склады',
			service: this.service,
		};

		this._dialog.open(MODALS.WarehouseModal, {data});
	}
}
