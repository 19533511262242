<app-column
	class="content"
	typeColumn="content"
>
	<div header>Номенклатура</div>
	<div content class="full-height">
		<app-dto-container
			[dataService]="service"
			(edit)="edit($event)"
		></app-dto-container>
	</div>
</app-column>
