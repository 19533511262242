import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

import { IDataServiceInterface } from '@shared/components/dto-viewer/models/data-service-interface';
import { DataConfig, DataConfigType } from '@shared/components/dto-viewer/models/data-config';
import { DtoService } from '@shared/components/dto-viewer/services/dto-service';
import { SdwebRepository } from '@shared/repositories/sdweb.repository';
import { NomenclatureService } from '../../plan/services/nomenclature.service';
import { StoreService } from '../../plan/services/store.service';
import { EntityConstantEnums } from '@shared/constants/entity-constant.enums';
import { MovementNomenclature } from '@store/data/plan/store/movement-nomenclature';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UnitsSelector } from '@store/units-store/units.selector';
import { LocationSelector } from '@store/location-store/location.selector';
import { cloneDeep } from 'lodash';
import { UsersSelector } from '@store/user-store/users.selector';

@Injectable({
	providedIn: 'root'
})
export class MovementNomenclatureService implements IDataServiceInterface {
	activeItem: MovementNomenclature;
	itemList: MovementNomenclature[] = [];
	itemMap: Map<number, MovementNomenclature> =
		new Map<number, MovementNomenclature>();

	activeDataConf: DataConfig[] = [];
	tableDataConf: DataConfig[] = [];

	dictionaryMap: Map<string, Map<number, any>> =
		new Map<string, Map<number, any>>();
	dictionaryList: Map<string, any[]> = new Map<string, any[]>();

	dtoTransfer: DtoService;

	constructor(
		private mesApi: SdwebRepository,
		private notifierService: NotifierService,
		private nomenclatureService: NomenclatureService,
		private loaderService: NgxUiLoaderService,
		private storeService: StoreService,
		private unitsSelector: UnitsSelector,
		private _usersSelector: UsersSelector,
		private locationSelector: LocationSelector,
	) {
		this.dtoTransfer = new DtoService(
			this, EntityConstantEnums.nomenclature_movement, this.notifierService, this.loaderService, this.mesApi
		);

		this.asyncCheck();

		this.activeDataConf.push(
			new DataConfig('id', 'id', DataConfigType.numeric, true)
		);
		this.activeDataConf.push(
			new DataConfig('nomenclatureId', 'Номенклатура', DataConfigType.selector)
		);
		this.activeDataConf.push(
			new DataConfig('startStoreId', 'Начальный склад', DataConfigType.selector)
		);
		this.activeDataConf.push(
			new DataConfig('endStoreId', 'Конечный склад', DataConfigType.selector)
		);
		this.activeDataConf.push(
			new DataConfig('taskType', 'Тип поступления', DataConfigType.numeric)
		);
		this.activeDataConf.push(
			new DataConfig('task', 'Задача', DataConfigType.string)
		);
		this.activeDataConf.push(
			new DataConfig('quantity', 'Колличество', DataConfigType.numeric)
		);
		this.activeDataConf.push(
			new DataConfig(
				'receiptLocationId', 'Локация списания', DataConfigType.selector, false, 'nameDescription'
			)
		);
		this.activeDataConf.push(
			new DataConfig(
				'receiptUnitId', 'Оборудование списания', DataConfigType.selector
			)
		);
		this.activeDataConf.push(
			new DataConfig('receiptUserId', 'На кого списать', DataConfigType.selector)
		);
		this.activeDataConf.push(
			new DataConfig('completeDate', 'Дата поступления', DataConfigType.dataIn)
		);

		this.tableDataConf.push(
			new DataConfig('nomenclatureId', 'Номер', DataConfigType.id, true, 'partnumber')
		);
		this.tableDataConf.push(
			new DataConfig('nomenclatureId', 'Номенклатура', DataConfigType.id)
		);
		this.tableDataConf.push(
			new DataConfig('startStoreId', 'Начальный склад', DataConfigType.id)
		);
		this.tableDataConf.push(
			new DataConfig('endStoreId', 'Конечный склад', DataConfigType.id)
		);
		this.tableDataConf.push(
			new DataConfig('taskType', 'Тип поступления', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('task', 'Задача', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('quantity', 'Колличество', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('receiptLocationId', 'Локация списания', DataConfigType.id)
		);
		this.tableDataConf.push(
			new DataConfig('receiptUnitId', 'Оборудование списания', DataConfigType.id)
		);
		this.tableDataConf.push(
			new DataConfig('receiptUserId', 'На кого списать', DataConfigType.id)
		);
		this.tableDataConf.push(
			new DataConfig('completeDateId', 'Дата поступления', DataConfigType.dataOut)
		);

		this.dictionaryList.set('nomenclatureId',  this.nomenclatureService.itemList);
		this.dictionaryMap.set('nomenclatureId', this.nomenclatureService.itemMap);

		this.dictionaryList.set('startStoreId', this.storeService.itemList);
		this.dictionaryMap.set('startStoreId', this.storeService.itemMap);
		this.dictionaryList.set('endStoreId', this.storeService.itemList);
		this.dictionaryMap.set('endStoreId', this.storeService.itemMap);

		// Заполняем локации из стореджа
		const locationsFlatList = cloneDeep(this.locationSelector.getLocationList());
		const locationsFlatMap = new Map<number, any>();

		locationsFlatList.forEach(value => {
			// @ts-ignore
			value.nameDescription = value.name + ' - ' + value.description;
			locationsFlatMap.set(value.id, value);
		});

		this.dictionaryList.set('receiptLocationId', locationsFlatList);
		this.dictionaryMap.set('receiptLocationId', locationsFlatMap);

		// Заполняем оборудование из стореджа
		const unitList = [];
		const unitMap = new Map<number, any>();

		this.unitsSelector.getAllowedUnits().forEach(value => {
			const unit = {
				id: value.id,
				name: value.inventoryNumber + ' - ' +
					value.unitType.name + ' (' + value.location.name + ')'
			};

			unitList.push(unit);
			unitMap.set(value.id, unit);
		});

		this.dictionaryList.set('receiptUnitId', unitList);
		this.dictionaryMap.set('receiptUnitId', unitMap);

		// Заполняем пользователей из стореджа
		const userList = [];
		const userMap = new Map<number, any>();

		this._usersSelector.getAllUsers().forEach(value => {
			const user = {
				id: value.id,
				name: value.tabNumber + ' ' + value.surname +
					' ' + value.name + ' ' + value.patronymic
			};
			userList.push(user);
			userMap.set(value.id, user);
		});

		this.dictionaryList.set('receiptUserId', userList);
		this.dictionaryMap.set('receiptUserId', userMap);
	}

	public asyncCheck(): void {
		this.dtoTransfer.asyncCheck();
	}

	public asyncUpdate(): void {
		this.dtoTransfer.asyncUpdate();
	}

	public saveActiveItem(data: MovementNomenclature): void {
		this.dtoTransfer.save([data]);
	}

	public deleteActiveItem(ids: number[]): void {
		this.dtoTransfer.delete(ids);
	}

}
