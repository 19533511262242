import {ChangeDetectionStrategy, Component} from '@angular/core';

import {MovementNomenclatureService} from '../../services/movement-nomenclature.service';
import {MovementNomenclature} from '@store/data/plan/store/movement-nomenclature';
import {MODALS} from '@shared/modals/modals';
import {IWarehouseModalData} from '@shared/modals/warehouse-modal/models/warehouse-modal.types';
import {ModalsService} from '@shared/services/modals.service';

@Component({
	selector: 'app-movement-nomenclature',
	templateUrl: './movement-nomenclature.component.html',
	styleUrls: ['./movement-nomenclature.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MovementNomenclatureComponent {
	constructor(
		private _dialog: ModalsService,
		public service: MovementNomenclatureService
	) {
	}

	public edit(movementNomenclature: MovementNomenclature): void {
		if (!movementNomenclature.completeDate) {
			movementNomenclature.completeDate = new Date();
		}

		const data: IWarehouseModalData = {
			title: 'Склады',
			service: this.service,
		};

		this._dialog.open(MODALS.WarehouseModal, {data});
	}
}
