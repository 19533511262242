import {Injectable} from '@angular/core';
import {NotifierService} from 'angular-notifier';

import {SdwebRepository} from '@shared/repositories/sdweb.repository';
import {MesCounterparty} from '@store/data/plan/mes-counterparty';
import {DataConfig, DataConfigType} from '@shared/components/dto-viewer/models/data-config';
import {DtoService} from '@shared/components/dto-viewer/services/dto-service';
import {IDataServiceInterface} from '@shared/components/dto-viewer/models/data-service-interface';
import {StoreNomenclature} from '@store/data/plan/store/store-nomenclature';
import {EntityConstantEnums} from '@shared/constants/entity-constant.enums';
import {NomenclatureService} from '../../plan/services/nomenclature.service';
import {StoreService} from '../../plan/services/store.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable({
	providedIn: 'root'
})
export class StoreNomenclatureService implements IDataServiceInterface {
	activeItem: StoreNomenclature;
	itemList: StoreNomenclature[] = [];
	itemMap: Map<number, StoreNomenclature> = new Map<number, StoreNomenclature>();

	activeDataConf: DataConfig[] = [];
	tableDataConf: DataConfig[] = [];

	dictionaryMap: Map<string, Map<number, any>> =
		new Map<string, Map<number, any>>();
	dictionaryList: Map<string, any[]> = new Map<string, any[]>();

	dtoTransfer: DtoService;

	constructor(
		private _mesApi: SdwebRepository,
		private _notifierService: NotifierService,
		private _loaderService: NgxUiLoaderService,
		private _nomenclatureService: NomenclatureService,
		private _storeService: StoreService
	) {
		this.dtoTransfer = new DtoService(
			this, EntityConstantEnums.store_nomenclature, _notifierService, _loaderService, _mesApi
		);

		this.asyncCheck();

		this.activeDataConf.push(
			new DataConfig('id', 'id', DataConfigType.string, true)
		);
		this.activeDataConf.push(
			new DataConfig('storeId', 'Склад', DataConfigType.selector)
		);
		this.activeDataConf.push(
			new DataConfig('nomenclatureId', 'Номенклатура', DataConfigType.selector)
		);
		this.activeDataConf.push(
			new DataConfig('balance', 'Баланс', DataConfigType.numeric)
		);
		this.activeDataConf.push(
			new DataConfig('actually', 'Актуально', DataConfigType.numeric)
		);
		this.activeDataConf.push(
			new DataConfig('slot', 'Стеллаж', DataConfigType.string)
		);
		this.activeDataConf.push(
			new DataConfig('cell', 'Ячейка', DataConfigType.string)
		);
		this.activeDataConf.push(
			new DataConfig('receiptDate', 'Дата поступления', DataConfigType.dataIn)
		);

		this.tableDataConf.push(
			new DataConfig('storeId', 'Склад', DataConfigType.id)
		);
		this.tableDataConf.push(
			new DataConfig('nomenclatureId', 'Номер', DataConfigType.id, true, 'partnumber')
		);
		this.tableDataConf.push(
			new DataConfig('nomenclatureId', 'Номенклатура', DataConfigType.id)
		);
		this.tableDataConf.push(
			new DataConfig('balance', 'На балансе', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('actually', 'Актуально', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('slot', 'Стеллаж', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('cell', 'Ячейка', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('receiptDate', 'Дата поступления', DataConfigType.dataOut)
		);

		this.dictionaryList.set('nomenclatureId', _nomenclatureService.itemList);
		this.dictionaryMap.set('nomenclatureId', _nomenclatureService.itemMap);

		this.dictionaryList.set('storeId', _storeService.itemList);
		this.dictionaryMap.set('storeId', _storeService.itemMap);
	}

	public asyncCheck(): void {
		this.dtoTransfer.asyncCheck();
	}

	public asyncUpdate(): void {
		this.dtoTransfer.asyncUpdate();
	}

	public saveActiveItem(data: MesCounterparty): void {
		this.dtoTransfer.save([data]);
	}

	public deleteActiveItem(ids: number[]): void {
		this.dtoTransfer.delete(ids);
	}


}
